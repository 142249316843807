import { Inject, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { constants } from "@pepconnect/constants";
import { CustomWindow, WINDOW } from "@pepconnect/providers/window-provider";

const DEFAULT_WINDOW_OPTIONS = constants.DEFAULT_POPUP_WINDOW_OPTIONS;

@Injectable({
  providedIn: 'root'
})
export class WindowPopupService {
  constructor(@Inject(WINDOW) private window: CustomWindow, private translate: TranslateService) { }

  openPopupWindow(href: string, id = '_blank', options = DEFAULT_WINDOW_OPTIONS) {
    return new Promise<Window>((resolve, reject) => {
      let popup = this.window.open(href, id, options);
      if (popup == null || typeof (popup) == 'undefined') {
          alert(this.translate.instant('POPUP_BLOCKED_MESSAGE'));
          reject();
      } else {
          popup.focus();
          resolve(popup);
      }
    });
  }
}
